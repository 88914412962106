<template>
  <div class="b-container">
    <!-- <div class="b-header">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :key="route.name" v-for="route in matches">
          <router-link :to="route.path">
            {{ $t(`menu.${route.name}`) }}
          </router-link>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <div class="b-inner">
      <div v-if="$slots.search">
        <div class="b-search" ref="search">
          <el-form class="search-panel" :label-position="LABELPOSITION">
            <el-row type="flex" justify="start" class="search-title">
              <img class="search-icon" src="../../assets/img/search-light.png" alt="">
              <span>{{ $t('label.menu.search') }}</span>
              <div class="fold">
                <div v-if="openBtn" @click="openshow" class="expand">
                  <span> {{ open ? $t('label.menu.close') : $t('label.menu.open') }}</span>
                  <el-icon :class="['open-arrow', open ? 'hide-arrow' : '']">
                    <ArrowDown />
                  </el-icon>
                </div>
              </div>
            </el-row>
            <div v-if="open" style="margin-top:10px">

              <slot name="search" v-if="open"></slot>
              <slot name="search-more" v-if="open"></slot>
            </div>
            <el-row :gutter="24" type="flex" align="middle" justify="end" >

              <slot name="search-action" v-if="open"></slot>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="b-container-list">
        <div class="b-operation" v-if="$slots['table-title'] || $slots.operation">
          <div class="b-table-title">
            <div class="custom-title">
              <img class="result-list-icon" src="../../assets/img/team-light.png" alt="">
              <div> {{ $t('label.menu.table') }}</div>
              <slot name="table-title"></slot>
            </div>
            <slot name="operation"></slot>
          </div>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import  $bus from '@/utils/Bus'
// 外部依赖
import {
  ref, computed, onMounted, useSlots
} from 'vue';
import { useRouter } from 'vue-router';

// icon
import { ArrowDown } from '@element-plus/icons-vue';

import { LABELPOSITION } from '@/theme/themeProps.d';

// the reson why element css is `smart` import
import 'element-plus/es/components/button/style/css.mjs';
// 定义router
const router = useRouter();

// 获取当前路由
const { value } = router.currentRoute;

// 展开按钮是否显示
const openBtn = ref(false);

// 是否展开
const open = ref(false);

const matches: Record<string, any> = computed((e) => value.matched.filter((item) => item.name)
  .map((item) => {
    const { name, path } = item;
    return { name, path: path || '/' };
  }));

 

const openshow= () => {
  open.value = !open.value
  if(open.value==false){
    let ovh='62.6vh'
    $bus.emit('car', ovh)
  }else{
    let ovh='44.3vh'
    $bus.emit('car',ovh)
  }
}

//计算div的高度  
const search = ref(null);  
const computedHeight = computed(() => {  
  if (search.value) {
    return search.value.offsetHeight; // 返回div的高度  
  }  
  return 0;  
});  
  
 
// 判断是否有search-more   有就显示展开按钮 没有就隐藏
const slots = useSlots();
onMounted(() => {
  openBtn.value = true;
  open.value = true;
 
  // if (slots['search-more']) {
  //   openBtn.value = true;
  // }
});

</script>
<style lang="less" scoped>
@import (reference) '@/assets/styles/variables.less';

.b-container {
  height: 100%;
  overflow: hidden;
  background-color: transparent;

  .b-header {
    position: relative;
    z-index: 2;
    padding: 18px 17px 16px;

    &.b-header--shadow {
      box-shadow: @shadow-1;
    }
  }

  .b-inner {
    display: flex;
    flex-direction: column;
    // grid-template-rows: auto 1fr;
    height: calc(100% - 53px);
    padding: 0px 17px 0px;
    overflow-y: overlay;
     background-color: rgba(0,0,0,0.1);
    &::-webkit-scrollbar {
      width: 16px;
      //height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 12px;
      //-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
      //background: rgba(0,0,0,0.2);
      border: 6px solid rgba(0, 0, 0, 0);
      box-shadow: 8px 0 0 #a5adb7 inset;

      &:hover {
        box-shadow: 8px 0 0 #8e9bac inset;
      }
    }

    &::-webkit-scrollbar-track {
      border-radius: 0;
      background: transparent;
    }
  }

  :deep(.el-pagination-options) {
    .el-select {
      min-width: 140px;
    }
  }

  .b-search {
    display: flex;
    align-items: flex-end;
    width: 100%;
    // margin-bottom: 8px;

    :deep(.el-form) {
      width: 100%;

      // .el-form-item {
      //     display: flex;
      // }
      .el-btn,
      .el-form-item,
      .el-form-model-item {
        margin-bottom: 8px;
      }

      .el-select,
      .el-input {
        min-width: 175px;
      }

      .button-group {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-bottom: 10px;
      }
    }

    :deep(.el-col .el-form-item__label) {
      text-align: left;
      color: #ffffff;
      font-size: 13px;
    }

    :deep(.el-col .el-form-item__label),
    :deep(.el-form-item-control) {
      line-height: 24px;
    }
  }

  :deep(.b-operation) {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;

    .b-table-title {
      margin-right: auto;
      display: flex;
      width: 100%;
      justify-content: space-between;

      .custom-title {
        display: flex;
        height: 100%;
        align-items: center;

        .result-list-icon {
          width: 20px;
          height: 20px;
          margin-right: 12px;
          background-size: 100% 100%;
        }

        div {
          color: #252628;
          font-size: 16px;
          
          font-weight: 700;
          margin-right: 20px;
        }

        span {
         
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          color: #979797;

          span {
            color: #1C69D4;
          }

          /* identical to box height, or 200% */
        }
      }
    }
  }

  :deep(.el-breadcrumb .el-breadcrumb-link) {
    .router-link-active:not(.router-link-exact-active) {
      color: rgba(15, 48, 122, 0.6);

      &:hover {
        color: @primary-hover;
      }
    }

    .router-link-exact-active {
      color: #0F307A;
      font-weight: bold;
    }
  }

  :deep(.el-breadcrumb-separator) {
    color: rgba(15, 48, 122, 0.6);
  }

  :deep(.open-arrow) {
    font-size: 12px;
    transition: all .3s;
  }

  :deep(.hide-arrow) {
    transform: rotate(180deg);
  }

  .search-panel {
    /* Note: backdrop-filter has minimal browser support */
    //border-radius: 8px;
    padding: 15px 16px 0;
    overflow: hidden;
    //background: rgba(255, 255, 255, 0.8);
    ////border: 1px dashed #FFFFFF;
    //backdrop-filter: blur(20px);
    background: linear-gradient(142deg, rgba(5, 20, 60, 0.6) 0%, rgba(23, 81, 151, 0.5) 100%);
box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.3);
border-radius: 8px 8px 8px 8px;
border: 1px solid;
border-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, .5), rgba(255, 255, 255, 0)) 1 1;
    // border: 1px solid;
    

  }

  .search-title {
    // margin-bottom: 20px;

    .search-icon {
      width: 24px;
      height: 24px;
    }

    span {
      margin-left: 8px;
      line-height: 25px;
      font-size: 16px;
      color: #ffffff;
      font-weight: bold;
    }
  }

  .b-container-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    /* Note: backdrop-filter has minimal browser support */
    padding: 24px 16px;
    margin-bottom: 6px;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(8px);
    // border: 1px solid;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
    clip-path: inset(0 round 8px);
  }

  .el-table-tbody {
    .el-menu {
      background: transparent !important;
    }

    .el-menu-inline-collapsed {
      width: 28px !important;
      border-right: none !important;
      margin-right: 0 !important;
    }

    .el-menu-inline-collapsed>.el-menu-item,
    .el-menu-inline-collapsed>.el-menu-item-group>.el-menu-item-group-list>.el-menu-item,
    .el-menu-inline-collapsed>.el-menu-item-group>.el-menu-item-group-list>.el-menu-submenu>.el-menu-submenu-title,
    .el-menu-inline-collapsed>.el-menu-submenu>.el-menu-submenu-title {
      width: 28px !important;
      height: 28px !important;
      padding: 0 !important;
      margin: 0 !important;
      background: transparent;

      span {
        display: flex;
        justify-content: center;
        align-content: center;
        height: 100%;

        img {
          width: 18px;
        }
      }
    }

    .el-menu-submenu-active {
      background: #DCE3EF;
      border-radius: 2px;
    }
  }

  .el-form-item-label>label {
    color: #252628;
    font-size: 13px;
  }

  .el-table-thead {
    background-size: 130% 100%;
    background-position: 90%;

    tr>th {
      padding: 12px;
    }
  }

  .el-table-fixed {
    .el-table-thead {
      background-size: 1300% 100% !important;
      //background-size: 150% 100%;
      background-position: 100% !important;
    }
  }
}

.fold {
  flex: 1;
  text-align: right;
}

.expand {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff !important;
  display: inline;
  cursor: pointer;

  //margin-right: 10px;
  span {
    color: #ffffff !important;
    margin-right: 4px;
    font-weight: normal !important;
    font-size: 14px !important;
  }
}</style>
