/*
 * @Description: static routes
 */

import { fa } from "element-plus/es/locale";

//! If unnecessary, please don't set name in root, otherwise it will be shown in breadcrumb.

export default [
  {
    root: {},
    meta: { alive: true },
    path: '/',
    hidden: false,
    redirect: '/home',
    component: 'layout',
    permissions: [],
    children: [
      {
        name: 'home',
        meta: { alive: true, fullScreen: true },
        path: '/home',
        hidden: false,
        component: 'home/index.vue',
        permissions: [],
      },
      {
        name: 'system',
        meta: { alive: true },
        path: '/system',
        hidden: false,
        redirect: '/system/hompage',
        component: 'RouterView',
        permissions: [],
        children: [
          {
            name: 'system.homgpage',
            meta: { defaultIcon: 'Delegation', activeIcon: 'DelegationFilled', alive: false },
            path: '/system/hompage',
            hidden: false,
            component: 'system/hompage/hompg.vue',
            permissions: [],
          },
          {
            name: 'system.customs',
            meta: { defaultIcon: 'Delegation', activeIcon: 'DelegationFilled', alive: true },
            path: '/system/cus',
            component: 'RouterView',
            redirect:'/system/cus/rack',
            permissions: [],
            children: [
              {
                name: 'cus.rack',
                meta: { alive: true},
                path: '/system/cus/rack',
                hidden: false,
                component: 'system/cus/rack/rack.vue',
                permissions: ['rack.query'],
              }
            ],
          },
          {
            name: 'system.dpcard',
            meta: { defaultIcon: 'Discount', activeIcon: 'DelegationFilled', alive: true },
            path: '/system/dpcard',
            component: 'RouterView',
            hidden: false,
            redirect:'/system/dpcard/ledger',
            permissions: [],
            children: [
              {
                name: 'dpcard.ledger',
                meta: { alive: true},
                path: '/system/dpcard/ledger',
                hidden: false,
                component: 'system/dpcard/ledger/LedgerList.vue',
                permissions: ['ledger.query'],
              },
              {
                name: 'dpcard.tocq',
                meta: { alive: true},
                path: '/system/dpcard/tocq',
                hidden: false,
                component: 'system/dpcard/tocq/TocqList.vue',
                permissions: ['tocq.query'],
              }
            ],
          },
          {
            name: 'system.filemg',
            meta: { defaultIcon: 'Notification', activeIcon: 'NotificationFilled', alive: true },
            path: '/system/filemg',
            hidden: false,
            component: 'RouterView',
            redirect:'/system/filemgs/dpFileUpload',
            permissions: [],
            children:[
              {
                name: 'filemg.dpEPart',
                meta: { alive: false},
                path: '/system/filemgs/dpFileUpload',
                hidden: false,
                component: 'system/filemg/dpFileUpload/DpexportpartList.vue',
                permissions: ['dpFileUpload.query'],
              },
              {
                name: 'filemg.dpSeach',
                meta: { alive: true},
                path: '/system/filemgs/dpFileSearch',
                hidden: false,
                component: 'system/filemg/dpFileSearch/DpFileSearchList.vue',
                permissions: ['dpFileSearch.query'],
              }
              // {
              //   name: 'filemg.filemg',
              //   meta: { alive: false },
              //   path: '/system/filemgs/filemg',
              //   hidden: false,
              //   component: 'system/filemg/fileinfo/FilemgList.vue',
              //   permissions: ['filemg.query'],
              // },
              
              
              // {
              //   name: 'filemg.dpFReport',
              //   meta: { alive: false },
              //   path: '/system/filemgs/dpFReport',
              //   hidden: false,
              //   component: 'system/filemg/dpFReport/DpFReportList.vue',
              //   permissions: ['dpFReport.query'],
              // }
            ]
          },
          {
            name: 'system.dpShipNoCertificate',
            meta: { defaultIcon: 'Notification', activeIcon: 'NotificationFilled', alive: true },
            path: '/system/dpShipNoCertificate',
            hidden: false,
            component: 'RouterView',
            redirect:'/system/dpShipNoCertificate/dpShipNotice',
            permissions: [],
            children:[
              {
                name: 'filemg.pdoc',
                meta: { alive: true },
                path: '/system/filemgs/pdoc',
                hidden: false,
                component: 'system/filemg/pdoc/PdocList.vue',
                permissions: ['pdoc.query'],
              },
              {
                name: 'dpShipNoCertificate.dpShipNotice',
                meta: { alive: true},
                path: '/system/dpShipNoCertificate/dpShipNotice',
                hidden: false,
                component: 'system/dpShipNoCertificate/dpShipNotice/DpShipNoticeList.vue',
                permissions: ['dpShipNotice.query'],
              },
              {
                name: 'filemg.dpInspledger',
                meta: { alive: true},
                path: '/system/filemgs/dpInspledger',
                hidden: false,
                component: 'system/filemg/dpInspledger/DpInspledgerList.vue',
                permissions: ['dpInspledger.query'],
              },
              {
                name: 'dpShipNoCertificate.dpGoodsCert',
                meta: { alive: true},
                path: '/system/dpShipNoCertificate/dpGoodsCert',
                hidden: false,
                component: 'system/dpShipNoCertificate/dpGoodsCert/DpGoodsCertList.vue',
                permissions: ['dpGoodsCert.query'],
              },
            ]
          },
          {
            name: 'system.dpexport',
            meta: { defaultIcon: 'Notification', activeIcon: 'NotificationFilled', alive: true },
            path: '/system/dpexport',
            hidden: false,
            component: 'RouterView',
            redirect:'/system/dpexport/dpexportfta',
            permissions: [],
            children:[
              {
                name: 'dpexport.dpEPart',
                meta: { alive: true },
                path: '/system/dpexport/dpexportfta',
                hidden: false,
                component: 'system/dpexport/dpexportfta/DpexportpartList.vue',
                permissions: ['fta.query'],
              },
              {
                name: 'dpexport.dptoconfigure',
                meta: { alive: true },
                path: '/system/dpexport/dptocontypes',
                hidden: false,
                component: 'system/dpexport/dptocontypes/LedgerList.vue',
                permissions: ['declare-origin-template-type.query'],
              },
              {
                name: 'dpexport.dptoconadministration',
                meta: { alive: true },
                path: '/system/dpexport/dptocontemplates',
                hidden: false,
                component: 'system/dpexport/dptocontemplates/LedgerList.vue',
                permissions: ['declare-origin-template.query'],
              },
              {
                name: 'dpexport.dptocondeclare',
                meta: { alive: true },
                path: '/system/dpexport/dptocondedocs',
                hidden: false,
                component: 'system/dpexport/dptocondedocs/LedgerList.vue',
                permissions: ['sys-doc.query'],
              },
              {
                name: 'dpexport.new',
                meta: { alive: true },
                path: '/system/dpexport/certType/:certType/certName/:certName/id/:id',
                hidden: true,
                component: 'system/dpexport/dpexportfta/Dpexadd.vue',
                permissions: ['fta.query'],
              },
              {
                name: 'dpexport.edit',
                meta: { alive: true },
                path: '/system/dpexport/edit/certNo/:certNo/certType/:certType',
                hidden: true,
                component: 'system/dpexport/dpexportfta/Dpexadd.vue',
                permissions: ['fta.query'],
              },
              {
                name: 'dpexport.details',
                meta: { alive: true },
                path: '/system/dpexport/details/certNo/:certNo/certType/:certType',
                hidden: true,
                component: 'system/dpexport/dpexportfta/DpexaddDetails.vue',
                permissions: ['fta.query'],
              },
            ]
          },
          {
            name: 'system.psettings',
            meta: { defaultIcon: 'Delegation', activeIcon: 'DelegationFilled', alive: true },
            path: '/system/psettings',
            component: 'RouterView',
            hidden: false,
            redirect:'/system/psettings/dppqconfig',
            permissions: [],
            children: [
              {
                name: 'psettings.dppqconfig',
                meta: { alive: true},
                path: '/system/psettings/dppqconfig',
                hidden: false,
                component: 'system/psettings/dppqconfig/DppqconfigList.vue',
                permissions: ['dppqconfig.query'],
              }
            ],
          },
          {
            name: 'system.rbac',
            meta: { defaultIcon: 'Seting', activeIcon: 'SetingFilled', alive: true },
            path: '/system/rbac',
            hidden: false,
            redirect: '/system/rbac/user',
            component: 'RouterView',
            permissions: [],
            children: [
              {
                name: 'rbac.user',
                meta: { alive: true},
                path: '/system/rbac/user',
                hidden: false,
                component: 'system/rbac/user/UserList.vue',
                permissions: ['user.query'],
              },
              {
                name: 'rbac.role',
                meta: { alive: true},
                path: '/system/rbac/role',
                hidden: false,
                component: 'system/rbac/role/RoleList.vue',
                permissions: ['role.query'],
              },
              {
                name: 'rbac.permission',
                meta: { alive: true},
                path: '/system/rbac/permission',
                hidden: false,
                component: 'system/rbac/permission/PermissionList.vue',
                permissions: ['permission.query'],
              },
              {
                name: 'rbac.department',
                meta: { alive: true},
                path: '/system/rbac/department',
                hidden: false,
                component: 'system/rbac/department/DepartmentList.vue',
                permissions: ['department.query'],
              },
            ],
          },
          {
            name: 'system.notification',
            meta: { defaultIcon: 'Notification', activeIcon: 'NotificationFilled', alive: true },
            path: '/system/notification',
            hidden: false,
            component: 'system/notification/NotificationList.vue',
            permissions: ['notification.query'],
          },
          {
            name: 'system.dictionary',
            meta: { defaultIcon: 'DataDictionary', activeIcon: 'DataDictionaryFilled', alive: true },
            path: '/system/dict',
            hidden: false,
            component: 'system/dict/DictList.vue',
            permissions: ['dict.query'],
          },
          {
            name: 'system.audit',
            meta: { defaultIcon: 'Audit', activeIcon: 'AuditFilled', alive: true },
            path: '/system/audit',
            hidden: false,
            component: 'system/audit/AuditList.vue',
            permissions: ['audit.query'],
          },
          {
            name: 'system.delegation',
            meta: { defaultIcon: 'Delegation', activeIcon: 'DelegationFilled', alive: true },
            path: '/system/delegation',
            hidden: false,
            component: 'system/delegation/DelegationList.vue',
            permissions: ['delegation.query'],
          },
          {
            name: 'system.jobManager',
            meta: { defaultIcon: 'Delegation', activeIcon: 'DelegationFilled', alive: true },
            path: '/system/jobManager',
            hidden: false,
            component: 'system/jobManager/JobManagerList.vue',
            permissions: ['job-Manager.query'],
          },
          
 
        ],
      },
      {
        name: 'system.notFound',
        meta: { icon: '' ,alive: true},
        path: '/404',
        hidden: true,
        component: 'exception/404.vue',
        permissions: [],
      },
    ],
  },
];
